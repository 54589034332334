import { ChainId } from '../../types/mod';

export const baseURL = 'https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/bridge/';

export const BRIDGE = {
    [ChainId.Linea]: [
        {
            titleImgSrc: baseURL + 'linea.png',
            title: 'Linea Bridge',
            content: "Linea's official bridge that can securely bridge Ethereum's native assets in the safest way possible.",
            link: 'https://bridge.linea.build/',
        },
        {
            titleImgSrc: baseURL + 'symbiosis.png',
            title: 'Symbiosis',
            content: 'Symbiosis is a cross-chain AMM DEX. You can easily swap any token and move your assets across different networks.',
            link: 'https://app.symbiosis.finance/swap?chainIn=Linea&chainOut=ZkSync%20Era&tokenIn=ETH&tokenOut=0x16A9494e257703797D747540f01683952547EE5b',
        },
        {
            titleImgSrc: baseURL + 'rubic.png',
            title: 'Rubic',
            content: 'Cross-chain technology aggregator + Tools to enable it for dApps.',
            link: 'https://app.rubic.exchange/?fromChain=LINEA&toChain=ZK_SYNC&from=iZi&to=iZi',
        },
        {
            titleImgSrc: baseURL + 'hop.png',
            title: 'Hop',
            content:
                'Hop is a scalable rollup-to-rollup general token bridge. It allows users to send tokens from one rollup or sidechain to another almost immediately.',
            link: 'https://app.hop.exchange/#/send?token=ETH&destNetwork=linea&sourceNetwork=ethereum',
        },
    ],
    [ChainId.LineaTest]: [
        {
            titleImgSrc: baseURL + 'hop.png',
            title: 'Hop',
            content:
                'Hop is a scalable rollup-to-rollup general token bridge. It allows users to send tokens from one rollup or sidechain to another almost immediately.',
            link: 'https://goerli.hop.exchange/#/send?token=ETH&destNetwork=linea&sourceNetwork=ethereum',
        },
        {
            titleImgSrc: baseURL + 'linea.png',
            title: 'Linea Faucet',
            content: "Linea's testnet ERC20 Faucet. Drip here non-valuable tokens to build or interact with Linea Goerli Testnet dApps.",
            link: 'https://faucet.goerli.linea.build/',
        },
    ],
} as any;
