import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { ChainId, TokenSymbol, FarmDynamicRangeiZiContractVersion } from '../../../../types/mod';
import { sortedToken } from '../../../../utils/tokenMath';

export interface MiningPoolMetaConfig {
    tokenA: TokenInfoFormatted;
    tokenB: TokenInfoFormatted;
    feeTier: FeeTier;
    miningContract?: any;
    iZiSwapAddress: string;
    initialToggle?: boolean;

    iZiBoost?: boolean;
    veiZiBoost?: boolean;
    contractVersion?: FarmDynamicRangeiZiContractVersion;
    twoRewards?: boolean;
    noFee?: boolean;
    feeCharged?: number;

    priceRangeRatio?: number;

    // if priceRangeRatio is undefined, means
    // leftRangeRatio != rightRangeRatio
    // leftRangeRatio >= 1
    leftRangeRatio?: number;
    // rightRangeRatio >= 1
    rightRangeRatio?: number;
    isEnded?: boolean;

    useTimestamp?: boolean;
    useOriginLiquidity?: true,

    additionalKey?: string;
}

export const miningPoolConfigList: Partial<Record<ChainId, MiningPoolMetaConfig[]>> =
    process.env.REACT_APP_ENV === 'production'
        ? {
            [ChainId.EthereumMainnet]: [],
            [ChainId.Matic]: [],
            [ChainId.BSC]: [],
            [ChainId.Arbitrum]: [],
            [ChainId.Linea]: [
                {
                    ...sortedToken(TokenSymbol.LAB, TokenSymbol.USDC, ChainId.Linea),
                    miningContract: '0xbE138aD5D41FDc392AE0B61b09421987C1966CC3',
                    iZiSwapAddress: '0x29db1d0bd8cb244f90743c4b7681e23fb7f32694',
                    feeTier: 1,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    leftRangeRatio: 10,
                    rightRangeRatio: 10,
                    isEnded: false,
                    useTimestamp: true,
                },
            ],
        } : {
            [ChainId.Rinkeby]: [],
            [ChainId.BSCTestnet]: [],
            [ChainId.AuroraTestnet]: [],
            [ChainId.ZkSyncAlphaTest]: [
                {
                    ...sortedToken(TokenSymbol.MIT, TokenSymbol.USDT, ChainId.ZkSyncAlphaTest),
                    miningContract: '0x254C23Db2E3D309c6025Ff54eb5f6aD6a25fFc43',
                    iZiSwapAddress: '0x6D7607fe4457A82BC7bAf1F22B038accC7044068',
                    useTimestamp: true,
                    feeTier: 0.2,
                    twoRewards: true,
                    iZiBoost: true,
                    contractVersion: FarmDynamicRangeiZiContractVersion.V1,
                    noFee: false,
                    feeCharged: 1,
                    leftRangeRatio: 2,
                    rightRangeRatio: 2,
                },
            ]
        };
