import { Box, Button, Flex, HStack, useColorMode, VStack, Text, BoxProps, Image } from '@chakra-ui/react';
import { BarPrices, CandlestickData, IChartApi, ISeriesApi, MouseEventParams, Time } from 'lightweight-charts';
import moment from 'moment';
import React, { useState, useCallback, useEffect } from 'react';
import { TokenInfoFormatted } from '../../../../hooks/useTokenListFormatted';
import { i_text_d, i_text_copy } from '../../../../style';
import { getColorThemeSelector } from '../../../../utils/funcs';
import { formatNumber } from '../../../../utils/tokenMath';
import { TokenIcons } from '../../components/TokenIcons';
import _ from 'lodash';
import { iZiSwapKLinesRecordEnum } from '../../../../net/iZUMi-endpoints/src/restful/api/analytics/izumiKlines';
import { Loading, LoadingEnum } from '../../../components/Loading';
import CandleChart from './CandleChart';
import Card from '../../../../components/Card/Card';

type AdvanceChartProps = {
    variant?: string;
    chartWidth: number;
    chartRef: React.RefObject<IChartApi>;
    series: React.RefObject<ISeriesApi<'Candlestick'>>;
    data: CandlestickData[];
    themeColor: any;
    tokenA: TokenInfoFormatted;
    tokenB: TokenInfoFormatted;
    selectedInterval: string;
    setSelectedInterval: (setSelectedInterval: iZiSwapKLinesRecordEnum) => void;
    showInterval?: iZiSwapKLinesRecordEnum[];
    chartLoading: boolean;
} & BoxProps;
const AdvanceChart: React.FC<AdvanceChartProps> = (props) => {
    const {
        variant,
        chartWidth,
        chartRef,
        series,
        data,
        themeColor,
        tokenA,
        tokenB,
        selectedInterval,
        setSelectedInterval,
        showInterval,
        chartLoading,
        ...rest
    } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    const [lastBarPrice, setLastBarPrice] = useState(0);
    const [lastTime, setLastTime] = useState(0 as Time);
    const [barPrice, setBarPrice] = useState(0);
    const [time, setTime] = useState(0 as Time);
    const [toggle, setToggle] = useState(false);
    const [filterData, setFilterData] = useState(data);
    useEffect(() => {
        if (toggle) {
            setFilterData(
                data.map(function (item) {
                    return { time: item.time, open: 1 / item.open, close: 1 / item.close, high: 1 / item.low, low: 1 / item.high };
                })
            );
        } else {
            setFilterData(data);
        }
    }, [toggle, data]);

    useEffect(() => {
        if (filterData.length > 0) {
            setBarPrice(filterData[filterData.length - 1].close);
            setTime(filterData[filterData.length - 1].time);
            setLastBarPrice(filterData[filterData.length - 1].close);
            setLastTime(filterData[filterData.length - 1].time);
        }
    }, [filterData]);

    const handleCrosshairMove = useCallback(
        _.throttle((param: MouseEventParams) => {
            //set bar price
            if (param.time) {
                if (series.current !== null) {
                    const price = param.seriesPrices.get(series.current) as BarPrices;
                    if (price !== undefined) {
                        setBarPrice(price.close);
                        setTime(param.time);
                    }
                }
            } else {
                if (filterData.length > 0) {
                    setBarPrice(lastBarPrice);
                    setTime(lastTime);
                } else {
                    setBarPrice(0);
                    setTime(0 as Time);
                }
            }
        }, 16),
        [filterData, lastBarPrice, lastTime]
    );

    return (
        <Card variant={variant ?? 'base'} w="100%" position="relative" {...rest}>
            <Flex alignItems="center" my="30px" mx="23px">
                <VStack alignItems="start" w="100%">
                    <HStack justifyContent="space-between" w="100%">
                        <HStack>
                            <TokenIcons tokenA={tokenA} tokenB={tokenB} initialToggle={false} />
                            <Flex direction="column" mx="14px">
                                <Text left="16px" top="30px" className={i_text_d} fontSize="32px" color={colorTheme('gray.800', 'gray.0')}>
                                    {formatNumber(barPrice, 2, 4, true)}
                                </Text>
                            </Flex>

                            <Text mt="10px !important" className={i_text_d} color="#0166FF">
                                {toggle ? tokenB.symbol + '/' + tokenA.symbol : tokenA.symbol + '/' + tokenB.symbol}
                            </Text>
                            <Image
                                mt="10px !important"
                                ml="9px"
                                src={process.env.PUBLIC_URL + '/assets/swap/transform.svg'}
                                onClick={() => {
                                    setToggle(!toggle);
                                }}
                                cursor="pointer"
                            />
                        </HStack>

                        <Flex className={i_text_copy} ml="auto">
                            <Button
                                mx="5px"
                                bg="inherit"
                                color={selectedInterval === iZiSwapKLinesRecordEnum.MINUTE_15 ? '#7F4AFE' : 'unset'}
                                onClick={() => {
                                    setSelectedInterval(iZiSwapKLinesRecordEnum.MINUTE_15);
                                }}
                                hidden={showInterval && !showInterval?.includes(iZiSwapKLinesRecordEnum.MINUTE_15)}
                            >
                                15min
                            </Button>
                            <Button
                                mx="5px"
                                bg="inherit"
                                color={selectedInterval === iZiSwapKLinesRecordEnum.HOUR_1 ? '#7F4AFE' : 'unset'}
                                onClick={() => {
                                    setSelectedInterval(iZiSwapKLinesRecordEnum.HOUR_1);
                                }}
                                hidden={showInterval && !showInterval?.includes(iZiSwapKLinesRecordEnum.HOUR_1)}
                            >
                                1h
                            </Button>
                            <Button
                                mx="5px"
                                bg="inherit"
                                color={selectedInterval === iZiSwapKLinesRecordEnum.DAY ? '#7F4AFE' : 'unset'}
                                onClick={() => {
                                    setSelectedInterval(iZiSwapKLinesRecordEnum.DAY);
                                }}
                                hidden={showInterval && !showInterval?.includes(iZiSwapKLinesRecordEnum.DAY)}
                            >
                                1d
                            </Button>
                            <Button
                                mx="5px"
                                bg="inherit"
                                color={selectedInterval === iZiSwapKLinesRecordEnum.WEEK ? '#7F4AFE' : 'unset'}
                                onClick={() => {
                                    setSelectedInterval(iZiSwapKLinesRecordEnum.WEEK);
                                }}
                                hidden={showInterval && !showInterval?.includes(iZiSwapKLinesRecordEnum.WEEK)}
                            >
                                1w
                            </Button>
                        </Flex>
                    </HStack>
                    <HStack>
                        <Text className={i_text_d} fontSize="13px">
                            {moment(Number(time) * 1000).format('YYYY-MM-DD HH:mm:ss')}
                        </Text>
                    </HStack>
                </VStack>
            </Flex>

            {chartLoading ? (
                <Loading
                    w={chartWidth + 20}
                    h="464px"
                    variant={LoadingEnum.purple}
                    justifyContent="center"
                    imgProps={{ boxSize: '16px' }}
                    textProps={{ fontSize: '14px' }}
                ></Loading>
            ) : (
                <Box w="100%" h="72%" mt="0px !important" p="10px">
                    <CandleChart
                        chartRef={chartRef}
                        chartWidth={chartWidth}
                        series={series}
                        data={filterData}
                        themeColor={themeColor}
                        handleCrosshairMove={handleCrosshairMove}
                    ></CandleChart>
                </Box>
            )}
        </Card>
    );
};

export default React.memo(AdvanceChart);
