import { ChevronDownIcon } from '@chakra-ui/icons';
import {
    Button,
    Menu,
    MenuButton,
    MenuItem,
    MenuList,
    Image,
    Text,
    useColorMode,
    HStack,
    BoxProps,
    Box,
    MenuButtonProps,
    IconProps,
} from '@chakra-ui/react';
import ArrowIcon from '../../../iZUMi-UI-toolkit/src/components/Icons/ArrowIcon/ArrowIcon';
import React, { useMemo } from 'react';
import chains from '../../../config/chains';
import { switchNetwork } from '../../../utils/metamaskWallet';
import { i_text_copy_bold, i_text_copy } from '../../../style';
import { getCurrentConnector, useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { RootDispatch } from '../../../state/store';
import { getColorThemeSelector } from '../../../utils/funcs';
import { useLocation } from 'react-router-dom';
import placeholder from '../../../assets/placeholder.png';
import { ConnectorNames } from '../../../utils/connectors';
import useAuth from '../../../hooks/useAuth';
import { useToast } from '../../../providers/ToastProvider';
import { useWeb3React } from '@web3-react/core';

type Props = {
    isHome?: boolean;
    showArrow?: boolean;
    buttonStyle?: MenuButtonProps;
    rightIconStyle?: IconProps;
} & BoxProps;

const NetworkSelect: React.FC<Props> = ({ isHome, showArrow = true, buttonStyle, rightIconStyle, ...rest }) => {
    const { chainId, account, chainIcon, chainName } = useWeb3WithDefault();
    const { library } = useWeb3React();
    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({
        dispatch,
    }));
    const { login } = useAuth();
    const { show } = useToast();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const bg1 = colorTheme('#EBEAEC', '#2D2E35');
    const bg2 = colorTheme('#F5F5F5', '#2D2E35');

    const location = useLocation();

    const chainsFilter = useMemo(() => {
        if (location.pathname.startsWith('/farm/uni')) {
            return chains.farmUni;
        }
        if (location.pathname.startsWith('/farm/iZi')) {
            return chains.farmiZi;
        }
        if (location.pathname.startsWith('/veiZi')) {
            return chains.veiZi;
        }
        if (location.pathname.startsWith('/trade')) {
            return chains.trade;
        }
        if (location.pathname.startsWith('/iPoints')) {
            return chains.iPoints;
        }
        return chains.all;
    }, [location]);

    return (
        <Box {...rest}>
            <Menu>
                {isHome ? (
                    <MenuButton
                        h="40px"
                        as={Button}
                        size="sm"
                        bg="transparent"
                        borderRadius="12px"
                        _hover={{ background: 'unset' }}
                        _active={{ background: 'unset' }}
                        leftIcon={
                            <Image src={process.env.PUBLIC_URL + chainIcon} boxSize="20px" fallbackSrc={placeholder} borderRadius="24px" />
                        }
                    >
                        <HStack>
                            <Text fontSize="13px !important" textAlign="left" className={i_text_copy}>
                                {chainName}
                            </Text>
                            {showArrow && <ArrowIcon dir="right" color="#4E529A" />}
                        </HStack>
                    </MenuButton>
                ) : (
                    <MenuButton
                        as={Button}
                        rightIcon={<ChevronDownIcon boxSize="24px" m="9px" background={bg1} borderRadius="5px" {...rightIconStyle} />}
                        leftIcon={
                            <Image
                                src={process.env.PUBLIC_URL + chainIcon}
                                boxSize="24px"
                                ml="17px"
                                mr="7px"
                                fallbackSrc={placeholder}
                                borderRadius="4px"
                            />
                        }
                        style={{
                            height: '40px',
                            //width: '178px',
                            padding: '0',
                            background: { bg2 } as unknown as string,
                            borderRadius: '6px',
                        }}
                        {...buttonStyle}
                    >
                        <Text ml="-10px" className={i_text_copy_bold}>
                            {chainName}
                        </Text>
                    </MenuButton>
                )}
                <MenuList>
                    {chainsFilter.map((chain) => (
                        <MenuItem
                            as={Button}
                            variant="light"
                            text={chain.name}
                            size="md"
                            key={chain.id}
                            isActive={chain.id === chainId}
                            style={{
                                display: 'flex',
                                flexDirection: 'row',
                                justifyContent: 'flex-start',
                                borderRadius: '4px',
                                overflow: 'hidden',
                                fontSize: '12px',
                                fontFamily: 'Montserrat-SemiBold',
                            }}
                            onClick={() => {
                                if (account) {
                                    // switchNetwork(chain.id)
                                    switchNetwork(chain.id, library).then((res) => {
                                        const currentConnector = getCurrentConnector();
                                        if (currentConnector == ConnectorNames.Injected) {
                                            login(ConnectorNames.Injected);
                                        }
                                        if (res) {
                                            show('Error : ', res.toString());
                                        }
                                    });
                                } else {
                                    dispatch.account.setOfflineChainId(chain.id);
                                }
                            }}
                            leftIcon={
                                <Image
                                    src={process.env.PUBLIC_URL + chain.icon}
                                    boxSize="20px"
                                    mr="10px"
                                    fallbackSrc={placeholder}
                                    borderRadius="4px"
                                />
                            }
                        >
                            {chain.name}
                        </MenuItem>
                    ))}
                </MenuList>
            </Menu>
        </Box>
    );
};

export default NetworkSelect;
