import { ChainId } from '../../types/mod';

export const baseURL = 'https://izumi-finance.oss-ap-southeast-1.aliyuncs.com/portal/';

export const PORTAL = {
    [ChainId.Linea]: [
        {
            titleImgSrc: baseURL + 'izi.svg',
            title: 'Main Site',
            content:
                "iZUMi.finance's main website, where all the supported public chains (mainnet), such as BSC, Arbitrum, Polygon, and Lp farming activities of iZiSwap are gathered.",
            link: 'https://izumi.finance',
        },
        // {
        //     titleImgSrc: baseURL + 'veiZi.svg',
        //     title: 'veiZi',
        //     content: 'Lock iZi for veiZi to get voting power and staking rewards on Ethereum. Become an owner of iZUMi',
        //     link: 'https://izumi.finance/veiZi/locker',
        // },
        // {
        //     titleImgSrc: baseURL + 'iZi-zksync.svg',
        //     title: 'iZiSwap@zkSync Era',
        //     content: 'A website specifically designed for iZiSwap on zkSync Era network, where iZiSwap is a top Dex on it.',
        //     link: 'https://zksync.izumi.finance',
        // },
        // {
        //     titleImgSrc: baseURL + 'iZi-scroll.svg',
        //     title: 'iZiSwap@Scroll',
        //     content: 'A website specifically designed for iZiSwap on Scroll network. It is currently in the testing phase. ',
        //     link: 'https://scroll.izumi.finance',
        // },
        // {
        //     titleImgSrc: baseURL + 'iZi-mantle.svg',
        //     title: 'iZiSwap@Mantle',
        //     content: 'A website specifically designed for iZiSwap on Mantle network. The mainnet is alive! ',
        //     link: 'https://mantle.izumi.finance',
        // },

        // {
        //     titleImgSrc: baseURL + 'iZi-base.svg',
        //     title: 'iZiSwap@Base',
        //     content: 'A website specifically designed for iZiSwap on Base network. Now the mainnet is alive. ',
        //     link: 'https://base.izumi.finance',
        // },
        // {
        //     titleImgSrc: baseURL + 'izi.svg',
        //     title: 'Portfolio',
        //     content: 'A tool provided by iZUMi for monitoring LP situations on different chains for concentrated AMM protocols.',
        //     link: 'https://portfolio.izumi.finance',
        // },
        // {
        //     titleImgSrc: baseURL + 'izi.svg',
        //     title: 'Test Env',
        //     content: "iZUMi's testing environment that allows you to experience it freely without worrying about security risks.",
        //     link: 'https://alpha.izumi.finance',
        // },
        {
            titleImgSrc: baseURL + 'axelar.png',
            title: 'Axelar',
            content: "iZUMi's close partner. Axelar delivers secure cross-chain communication for Web3.",
            link: 'https://axelar.network/',
        },
        {
            titleImgSrc: baseURL + 'airswap.svg',
            title: 'AirSwap',
            content:
                "iZUMi's close partner. AirSwap is an open-source community that powers the original peer-to-peer DEX - efficient swaps without slippage or counter-party risk.",
            link: 'https://www.airswap.io/#/',
        },
        {
            titleImgSrc: baseURL + 'gecko.png',
            title: 'Gecko Terminal',
            content: "iZUMi's close partner. A real-time DEX Tracker and the other half-blood of CoinGecko.",
            link: 'https://www.geckoterminal.com/linea/iziswap-linea/pools',
        },
    ],
} as any;
