import { Stack, HStack, Text, Divider, Image, useColorMode, Center, VStack } from '@chakra-ui/react';
import _ from 'lodash';
import { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useWeb3WithDefault } from '../../../../hooks/useWeb3WithDefault';
import CustomButton from '../../../../iZUMi-UI-toolkit/src/components/Buttons/CustomButton/CustomButton';
import Card from '../../../../iZUMi-UI-toolkit/src/components/Card/Card';
import { ResponseIPointsInfo } from '../../../../net/iZUMi-endpoints/src/restful/izumiGame';
import { i_text_copy } from '../../../../style';
import { getColorThemeSelector } from '../../../../utils/funcs';
import { Loading, LoadingEnum } from '../../../components/Loading';
type MyIPointsProps = {
    networkLoading: boolean;
    iPointsInfo: ResponseIPointsInfo;
    isShowAllHistory: boolean;
    setIsShowAllHistory: any;
};
const MyIPoints: React.FC<MyIPointsProps> = (props) => {
    const { networkLoading, iPointsInfo, isShowAllHistory, setIsShowAllHistory } = props;
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const { account } = useWeb3WithDefault();
    const { t } = useTranslation();

    const accIPointsInfo = useMemo(() => {
        if (!iPointsInfo.history) {
            return [];
        }
        const accumulatedArray: number[] = [];
        let total = iPointsInfo.history.reduce((total, item) => total + item.points, 0);
        iPointsInfo.history.map((item) => {
            accumulatedArray.push(total);
            total = total - item.points;
        });
        return accumulatedArray;
    }, [iPointsInfo.history]);
    return networkLoading ? (
        <Card
            w={{ base: '100%', sm: '438px', xxl: '488px' }}
            h={isShowAllHistory ? '581px' : '222px'}
            bg={colorTheme('#FDFEFF', '#151221')}
            position="relative"
            transition="height 0.5s ease"
            pb="20px"
        >
            <Loading variant={LoadingEnum.purple} text={t('Loading...')} pt="100px" pb="30px" />
        </Card>
    ) : (
        <Card
            w={{ base: '100%', sm: '438px', xxl: '488px' }}
            h={isShowAllHistory ? '581px' : '222px'}
            bg={colorTheme('#FDFEFF', '#151221')}
            position="relative"
            transition="height 0.5s ease"
            pb="20px"
        >
            <Stack px={{ base: '17px', sm: '36px' }} mt="21px" opacity={account ? 1 : 0.5}>
                <Text className={i_text_copy} color={'#6A5E86'}>
                    {t('My iPoints')}
                </Text>
                <Text className={i_text_copy} fontSize="24px !important" fontWeight="600" color={colorTheme('#24193B', '#F5F5F5')}>
                    {account ? iPointsInfo.totalPoints || '0' : '- -'}
                    <Text as="span" fontSize="20px" color={colorTheme('#442F70', '#8E7BB8')}>
                        {' '}
                        {t('iPoints')}
                    </Text>
                </Text>
            </Stack>

            <Stack
                minH="138px"
                bg={colorTheme(
                    'linear-gradient(180deg, #F2F3FF 0%, rgba(248, 248, 255, 0) 100%)',
                    'linear-gradient(186.19deg, #1F1937 4.89%, rgba(27, 23, 44, 0) 110.27%);'
                )}
                borderRadius="30px 30px 6px 6px"
                pl={{ base: '25px', sm: '36px' }}
                pr={{ base: '14px', sm: '20px' }}
                mt="16px"
            >
                <HStack
                    justifyContent="space-between"
                    position="sticky"
                    top="0px"
                    bg={colorTheme('#F4F5FF', '#1C1831')}
                    pt="20px"
                    pb="7px"
                    pr={{ base: '0px', sm: '16px' }}
                    zIndex="1"
                    opacity={account ? 1 : 0.5}
                >
                    <Text className={i_text_copy} fontWeight="600" color={colorTheme('#6220FF', '#996FFF')}>
                        {t('History')}
                    </Text>

                    <CustomButton
                        className={i_text_copy}
                        color={colorTheme('#6220FF', '#996FFF')}
                        text={isShowAllHistory ? <Text w="80px">{t('Show Less')}</Text> : <Text w="80px">{t('Show More')}</Text>}
                        leftIconElement={
                            <Image
                                w="10px"
                                h="15px"
                                src={
                                    isShowAllHistory
                                        ? colorTheme('/assets/iPoints/myPoints/shrink.svg', '/assets/iPoints/myPoints/darkShrink.svg')
                                        : colorTheme('/assets/iPoints/myPoints/expand.svg', '/assets/iPoints/myPoints/darkExpand.svg')
                                }
                                fallbackSrc={
                                    isShowAllHistory ? '/assets/iPoints/myPoints/shrink.svg' : '/assets/iPoints/myPoints/expand.svg'
                                }
                            ></Image>
                        }
                        onClick={() => {
                            setIsShowAllHistory(!isShowAllHistory);
                        }}
                    ></CustomButton>
                </HStack>
                <Stack minH={{ base: '78px', xxl: 'unset' }} h="100%" overflow="auto">
                    {account ? (
                        _.isEmpty(iPointsInfo) || (iPointsInfo.history && iPointsInfo.history.length === 0) ? (
                            <Center flex="1">
                                <VStack>
                                    <Text className={i_text_copy} color={'#4F54CF'}>
                                        {t("Oh! there's nothing here!")}
                                    </Text>
                                </VStack>
                            </Center>
                        ) : (
                            iPointsInfo.history &&
                            iPointsInfo.history.map((item, index) => {
                                return (
                                    <Stack key={index} spacing="7px">
                                        <HStack>
                                            <Text w={{ base: '76px', sm: '104px', xxl: '110px' }} className={i_text_copy} color="#7D6CA8">
                                                {new Date(parseInt(item.time) * 1000).toISOString().split('T')[0]}
                                            </Text>
                                            <Text w={{ base: '105px', sm: '142px', xxl: '150px' }} className={i_text_copy} color="#7D6CA8">
                                                {t(item.type)}
                                            </Text>

                                            <HStack w={{ base: '50px', sm: '80px', xxl: '90px' }} spacing={{ base: '4px', sm: '6px' }}>
                                                <Image
                                                    boxSize="11px"
                                                    src={colorTheme('/assets/iPoints/blueGift.svg', '/assets/iPoints/darkBlueGift.svg')}
                                                ></Image>
                                                <Text className={i_text_copy} fontWeight="600" color={colorTheme('#352360', '#C9BDE5')}>
                                                    {item.points}
                                                </Text>
                                            </HStack>

                                            <Text className={i_text_copy} color="#7D6CA8">
                                                {accIPointsInfo[index]}
                                            </Text>
                                        </HStack>
                                        <Divider w={{ base: '300px', sm: '416px', xxl: '416px' }}></Divider>
                                    </Stack>
                                );
                            })
                        )
                    ) : (
                        <Stack h="100%" px={{ base: '17px', sm: '30px' }} spacing="10px" mt="8px">
                            <Center flex="1">
                                <VStack>
                                    <Text className={i_text_copy} color={'#4F54CF'}>
                                        Oh! there&apos;s nothing here!
                                    </Text>
                                    <Text className={i_text_copy} color={'#4F54CF'}>
                                        Please connect wallet
                                    </Text>
                                </VStack>
                            </Center>
                        </Stack>
                    )}
                </Stack>
            </Stack>
        </Card>
    );
};
export default MyIPoints;
