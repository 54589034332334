import React from 'react';
import { TokenBalanceBlock } from './TokenBalanceBlock';
import { Box, BoxProps, ChakraProps, Divider, HStack } from '@chakra-ui/react';
import { i_h2 } from '../../../style';
import { AmountInput, AmountInputProps } from './AmountInput';
import { AdaptationMode } from '../../../components/layout/PageLayout';
import Card from '../../../components/Card/Card';

export type TokenAmountInputProps = {
    titleProps?: BoxProps;
    setIsInputFocus?: any;
    type?: AdaptationMode;
    iconProps?: ChakraProps;
    symbolProps?: ChakraProps;
    inputProps?: ChakraProps;
    balanceProps?: ChakraProps;
    toolProps?: ChakraProps;
} & AmountInputProps;

const TokenAmountInput: React.FC<TokenAmountInputProps> = (props) => {
    const {
        handleSetValue,
        token,
        children,
        price,
        balance,
        errorInfo,
        inputValue,
        titleProps,
        setIsInputFocus,
        type,
        iconProps,
        symbolProps,
        inputProps,
        balanceProps,
        toolProps,
        ...rest
    } = props;

    return type === 'mobile' ? (
        <Card variant="lightGary" w="100%" p="10px" {...rest}>
            <Box w="100%">
                <AmountInput
                    type="mobile"
                    handleSetValue={handleSetValue}
                    token={token}
                    price={price}
                    balance={balance}
                    errorInfo={errorInfo}
                    inputValue={inputValue}
                    w="100%"
                    fontClass={i_h2}
                    setIsInputFocus={setIsInputFocus}
                />
            </Box>
        </Card>
    ) : (
        <Card variant="customBlack" h="172px" w="100%" p="10px" {...rest}>
            <TokenBalanceBlock
                token={token}
                balance={balance}
                p="10px"
                iconProps={iconProps}
                symbolProps={symbolProps}
                balanceProps={balanceProps}
                {...titleProps}
            />
            <Divider m="0 !important" />
            <HStack w="100%" px="20px" mt="2px" justifyContent="space-between">
                {children}
            </HStack>

            <Box w="100%" px={{ base: '10px', sm: '0px', xxl: '10px' }}>
                <AmountInput
                    handleSetValue={handleSetValue}
                    token={token}
                    price={price}
                    balance={balance}
                    errorInfo={errorInfo}
                    inputValue={inputValue}
                    w="100%"
                    setIsInputFocus={setIsInputFocus}
                    toolProps={toolProps}
                    {...inputProps}
                />
            </Box>
        </Card>
    );
};

export default TokenAmountInput;
