export const announceConfig =
    process.env.REACT_APP_ENV === 'production'
        ? [
              {
                  text: 'Reminder: \xa0\xa0  iZiSwap is live on Linea mainnet !  Feel free to enjoy the blockchain world powered by iZiSwap and Linea !',
                  icon: '',
                  type: 'link',
                  linkText: 'Check more.',
                  onClick: null,
                  index: 0,
              },
          ]
        : [
              {
                  text: 'Reminder: \xa0\xa0  We are on Linea Testnet. Feel free to enjoy the blockchain world powered by iZiSwap and Linea !',
                  icon: '',
                  type: 'link',
                  linkText: 'Check more.',
                  onClick: null,
                  index: 0,
              },
          ];
