import React, { useEffect } from 'react';
import { Divider, HStack, Stack, useColorMode } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import PageLayout from '../../../../../components/layout/PageLayout';
import PageHeader from '../../../../../components/PageHeader/PageHeader';
import useIsMobile from '../../../../../hooks/useIsMobile';
import BackButton from '../../../../../iZUMi-UI-toolkit/src/components/Buttons/BackButton/BackButton';
import ChainNotValidBlockWrapper from '../../../../components/ChainNotValidBlock';
import AddLiquidityForm from '../AddLiquidityForm';
import { Heading, Text } from '../../../../../iZUMi-UI-toolkit/src/components/Typography/Typography';
import { i_text_d } from '../../../../../style';
import { useHistory } from 'react-router-dom';
import { getColorThemeSelector } from '../../../../../utils/funcs';
import CustomButton from '../../../../../components/CustomButton/CustomButton';

const AddCustomLiquidity: React.FC = () => {
    useEffect(() => {
        document.title = 'Liquidity | iZiSwap';
    }, []);
    const { t } = useTranslation();
    const isMobile = useIsMobile();
    const history = useHistory();
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);

    return isMobile ? (
        <PageLayout
            body={
                <ChainNotValidBlockWrapper
                    content={
                        <Stack w="100%" mt="20px">
                            <BackButton flex={20} />
                            <Stack flex={80} alignItems="center" mt="14px">
                                <Heading level={'4'}>{t('Add Liquidity')}</Heading>
                                <Text variant="display" fontSize="12px">
                                    {t('Add a new position to earn transaction fees.')}
                                </Text>
                                <CustomButton
                                    variant="lightBlue"
                                    className={i_text_d}
                                    fontSize="14px"
                                    fontWeight="600"
                                    _hover={{ opacity: 0.7 }}
                                    w="180px"
                                    h="36px"
                                    text="Popular >>"
                                    onClick={() => {
                                        history.push('/add-liquidity/popular');
                                    }}
                                ></CustomButton>
                            </Stack>
                            <Divider></Divider>
                            <AddLiquidityForm />
                        </Stack>
                    }
                    app="trade"
                />
            }
            type="mobile"
        />
    ) : (
        <PageLayout
            header={
                <PageHeader
                    headerTitle={
                        <HStack spacing="14px">
                            <Heading level={isMobile ? '4' : '3'}>{t('Add Liquidity')}</Heading>
                            <CustomButton
                                variant="lightBlue"
                                className={i_text_d}
                                fontSize="14px"
                                fontWeight="600"
                                _hover={{ opacity: 0.7 }}
                                w="180px"
                                h="36px"
                                text="Popular >>"
                                onClick={() => {
                                    history.push('/add-liquidity/popular');
                                }}
                            ></CustomButton>
                        </HStack>
                    }
                    subtitle={t('Add a new position to earn transaction fees.')}
                    variant="secondary"
                    alignItems="start"
                />
            }
            body={
                <ChainNotValidBlockWrapper
                    content={
                        <Stack w="100%">
                            <AddLiquidityForm />
                        </Stack>
                    }
                    app="trade"
                />
            }
            internalWidth={{ base: '700px', xl: '1150px' }}
        />
    );
};

export default AddCustomLiquidity;
