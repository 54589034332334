import { ParticleNetwork, UIMode, WalletEntryPosition } from '@particle-network/auth';
// import Web3 from 'web3';
import { AuthType } from '@particle-network/auth';
import { clearUserCache } from './clearCache';
import { setCurrentConnector } from '../hooks/useWeb3WithDefault';
import { ConnectorNames, DEFAULTCHAINID } from './connectors';

const particle = new ParticleNetwork({
    projectId: process.env.REACT_APP_PROJECT_ID as string,
    clientKey: process.env.REACT_APP_CLIENT_KEY as string,
    appId: process.env.REACT_APP_APP_ID as string,
    chainName: 'linea',
    chainId: 59140,
    // authUrl: process.env.REACT_APP_AUTH_URL as string, // use for demo internal test, developer can delete it.
    wallet: {
        displayWalletEntry: true,
        defaultWalletEntryPosition: WalletEntryPosition.BR,
        // supportChains: [
        //   {
        //     id: 1,
        //     name: "Ethereum",
        //   },
        //   // {
        //   //   id: 101,
        //   //   name: "Solana",
        //   // },
        // ],
    },
});

let theme = localStorage.getItem('dapp_particle_theme');
if (!theme) {
    theme = 'light';
}
particle.setAuthTheme({
    displayWallet: true,
    uiMode: theme as UIMode,
});

//rpcUrl used fot demo internal test, developer can delete it.
//set rpcUrl for internal test
// const particleProvider = new ParticleProvider(
//     particle.auth
//     // process.env.REACT_APP_RPC_URL ? process.env.REACT_APP_RPC_URL + "/evm-chain" : undefined
// );
//set rpcUrl for internal test
// const evmProvider = new EVMProvider(
//   particle.auth,
//   process.env.REACT_APP_RPC_URL ? process.env.REACT_APP_RPC_URL + "/evm-chain" : undefined
// );

// //set rpcUrl for internal test
// const solanaWallet = new SolanaWallet(
//   particle.auth,
//   process.env.REACT_APP_RPC_URL ? process.env.REACT_APP_RPC_URL + "/solana" : undefined
// );

// const particleWeb3 = new Web3(particleProvider as any);

// const ethersProvider = new ethers.providers.Web3Provider(particleProvider, "any");

// The provider also allows signing transactions to
// send ether and pay to change state within the blockchain.
// For this, we need the account signer...
// const ethersSigner = ethersProvider.getSigner();

// set debug for internal test, developer can remove it.
// if (process.env.REACT_APP_PARTICLE_ENV === 'development') {
//     window.particle.debug = true;
// }

const loginWithParticle = (type: AuthType) => {
    console.log('loginWithParticle');
    particle.auth
        .login({
            preferredAuthType: type,
            // account: input_content,
            supportAuthTypes: 'all',
            socialLoginPrompt: 'consent',
            loginFormMode: false,
            hideLoading: type === 'jwt',
        })
        .then((userInfo) => {
            setCurrentConnector(ConnectorNames.Particle);
        })
        .catch((error: any) => {
            if (error.code !== 4011) {
                console.error(error.message);
            }
        });
};

const logoutWithParticle = () => {
    console.log('logoutWithParticle');
    const hideLoading = !!particle.auth.userInfo()?.jwt_id;
    if (hideLoading) {
        console.info('custom loading...');
    }
    particle.auth
        .logout(hideLoading)
        .then(() => {
            console.log('logout success');
            clearUserCache();
            setCurrentConnector(ConnectorNames.Injected);
            // setConnect(false);
            // setNativeBalance("");
            // setLoginState(false);
        })
        .catch((err) => {
            console.log('logout error', err);
        });
};

export { particle, loginWithParticle, logoutWithParticle };
