import { Flex, useDisclosure, Box, Image, Text, BoxProps, useBreakpointValue, useColorMode, HStack, Stack } from '@chakra-ui/react';
import React, { useState } from 'react';
import useInterval from 'ahooks/lib/useInterval';

import { useHistory } from 'react-router-dom';
import Identicon from '../../../components/Identicon';
import WalletModal from '../../../components/Modal/WalletModal/WalletModal';
import { useRematchDispatch } from '../../../hooks/useRematchDispatch';
import { useWeb3WithDefault } from '../../../hooks/useWeb3WithDefault';
import { useConnectModal } from '../../../providers/ConnectProvider';
import { RootDispatch, RootState } from '../../../state/store';
import truncateWalletAddress from '../../../utils/truncateWalletAddress';
import { getColorThemeSelector } from '../../../utils/funcs';
import './Header.css';
import { useSelector } from 'react-redux';
import { links } from '../../../config/links';
import { ChainId, TokenSymbol } from '../../../types/mod';
import { formatNumber } from '../../../utils/tokenMath';
import LanguageSelect from '../../../components/Sidebar/LanguageSelect/LanguageSelect';
import NetworkSelect from '../../../components/Select/NetworkSelect/NetworkSelect';
import { DropDownMenu } from '../../../components/DropDownMenu/DropDownMenu';
import { useHover } from '../../../hooks/useHover';
import { useIsFixedHeader } from '../../../hooks/useLayoutType';
import BuyiZiButton from '../../../components/BuyiZiButton/BuyiZiButton';
import { i_text_copy } from '../../../style';
import ColorModeSelect from '../../../components/ColorModeSelect/ColorModeSelect';
import { getSafeTokenPrice } from '../../../state/models/token/funcs';
import ConnectButton from '../../../components/ConnectButton/ConnectButton';

const Header: React.FC<BoxProps> = (props) => {
    const { isOpen, onOpen, onClose } = useDisclosure();
    const { onOpenModal: onConnect } = useConnectModal();
    const { chainId, chainName, web3, account } = useWeb3WithDefault();
    const history = useHistory();
    const isPC = useBreakpointValue({ base: false, xl: true });
    const colorTheme = getColorThemeSelector(useColorMode().colorMode);
    const [showHeader, setShowHeader] = useState(true);
    const { token } = useSelector((state: RootState) => state);
    const { account: accountModel } = useSelector((state: RootState) => state);

    const isFixedHeader = useIsFixedHeader();

    const disConnect = () => {
        onOpen();
    };

    const onCopyAddress = () => {
        if (account) {
            navigator.clipboard.writeText(account);
        }
    };

    const { dispatch } = useRematchDispatch((dispatch: RootDispatch) => ({ dispatch }));
    const { pageLayout } = useSelector((state: RootState) => state);
    const [headerRef, isHovering] = useHover<any>();
    // const isXl =

    useInterval(() => {
        if (account && web3 && chainId) {
            dispatch.account.fetchEthBalanceAndUSDIfMissing({
                account,
                web3,
                chainId,
            });
        }
    }, 5000);

    const toolBlock = (
        <HStack spacing="7px" mt="0px !important">
            <ColorModeSelect showNav={true} flex="unset" ml="15px !important" />
            <LanguageSelect flex="unset" />

            {!isFixedHeader && (
                <Flex h="21px" ref={headerRef}>
                    <Image
                        cursor="pointer"
                        boxSize="21px"
                        src={colorTheme('/assets/header/changeLayout.svg', '/assets/header/darkchangeLayout.svg')}
                        fallbackSrc="/assets/header/HoverchangeLayout.svg"
                        onClick={() => {
                            setShowHeader(false);
                            dispatch.pageLayout.setIsInAnimation(true);
                            setTimeout(() => {
                                dispatch.pageLayout.setPageLayout('sidebar');
                            }, 500);
                            setTimeout(() => {
                                dispatch.pageLayout.setIsInAnimation(false);
                            }, 500);
                        }}
                    ></Image>
                </Flex>
            )}
            <BuyiZiButton
                h="25px"
                expand={true}
                tokenPrice={formatNumber(getSafeTokenPrice(token, TokenSymbol.IZI))}
                onClick={() => {
                    window.open(links.iZiBuyLink[chainId] ?? links.iZiBuyLink[ChainId.EthereumMainnet]);
                }}
                imageProps={{ boxSize: '15px' }}
                ml="17px !important"
            />
        </HStack>
    );
    const accountBlock = (
        <Flex alignItems="center" ml="0px !important" mt={{ base: '0px !important' }}>
            <Flex px="10px" py="2px" alignItems="center" pr="0px">
                <Flex alignItems="center" flexShrink={0} flexGrow={0} h={{ base: '30px', xl: '54px' }} {...props} zIndex="3">
                    <Flex m="0 auto" justifyContent="flex-end" align="center" flexDirection="row">
                        {account ? <NetworkSelect isHome={true} showArrow={false} /> : null}
                        {account ? (
                            <>
                                <Box h="32px" boxSize="border-box" borderRadius="2px" cursor="pointer" onClick={disConnect}>
                                    <Flex height="32px" padding="3px 6px" alignItems="center">
                                        <Box boxSize="22px">
                                            {' '}
                                            <Identicon size={22} />
                                        </Box>
                                        <Flex alignItems="center" ml="12px">
                                            <Text
                                                width="80px"
                                                lineHeight="12px"
                                                fontSize="12px"
                                                className={i_text_copy}
                                                fontWeight="600"
                                                color={colorTheme('#2A2A2A', '#EBEBEB')}
                                            >
                                                {truncateWalletAddress(account || '')}
                                            </Text>
                                            <Image
                                                src={
                                                    process.env.PUBLIC_URL +
                                                    colorTheme('/assets/header/HorizontalLine.svg', '/assets/wallet/balanceIconDark.png')
                                                }
                                                width="5px"
                                                height="9px"
                                                marginRight="4px"
                                                marginTop="1px"
                                            />
                                            <Text
                                                lineHeight="12px"
                                                fontSize="12px"
                                                className={i_text_copy}
                                                fontWeight="600"
                                                color={colorTheme('#2A2A2A', 'tertiary.50')}
                                            >
                                                {formatNumber(accountModel.ethBalance ?? 0, 2, 2)}
                                            </Text>
                                            <Image
                                                boxSize="10px"
                                                src="/assets/header/arrowDown.svg"
                                                ml="11px"
                                                mr={{ base: '0px', xxl: '40px' }}
                                            ></Image>
                                        </Flex>
                                    </Flex>
                                </Box>
                            </>
                        ) : (
                            isPC && <ConnectButton mr="10px" Identicon={Identicon} onClick={onConnect} />
                        )}
                    </Flex>
                </Flex>
            </Flex>
        </Flex>
    );
    return (
        <>
            <Flex
                className={pageLayout.inAnimation ? 'showHeader' : ''}
                alignItems="center"
                flexShrink={0}
                flexGrow={0}
                h={pageLayout.inAnimation ? '0px' : { base: '100px', xl: '60px' }}
                bg={colorTheme('#ffffff', '#0B071E')}
                position={'sticky'}
                top="0px"
                transitionProperty="all"
                transitionDuration="0.3s"
                transitionTimingFunction="ease"
                zIndex="1"
                {...props}
            >
                {showHeader && (
                    <Flex
                        w="100%"
                        h="100%"
                        m="0 auto"
                        alignItems={{ base: 'start', xl: 'center' }}
                        flexDirection={{ base: 'column', xl: 'row' }}
                    >
                        <HStack w={{ base: '100%', xl: 'unset' }} pr="20px">
                            <HStack w={{ base: 'unset', sm: '150px' }} justifyContent="center">
                                <Image
                                    src={process.env.PUBLIC_URL + '/assets/home/iziLogo/logo.svg'}
                                    width="38px"
                                    height={{ base: '59px', xl: '38px' }}
                                    cursor="pointer"
                                    onClick={() => {
                                        history.push('/home');
                                    }}
                                />
                            </HStack>
                            <DropDownMenu
                                buttonProps={{
                                    fontFamily: 'Montserrat-Medium !important',
                                    fontSize: '13px !important',
                                    px: '0px',
                                    _hover: { color: colorTheme('#32A9C9', '#7F4AFE'), bg: 'none !important' },
                                    _active: { color: colorTheme('#32A9C9', '#7F4AFE'), bg: 'none !important' },
                                    ml: '0px !important',
                                }}
                                menuListProps={{
                                    fontFamily: 'Montserrat-Medium',
                                    bg: colorTheme('#FFFFFF', '#292343'),
                                    color: colorTheme('#A7A1AC !important', '#655986 !important'),
                                    _hover: { color: colorTheme('#32A9C9 !important', '#7F4AFE !important') },
                                }}
                                h="100%"
                                alignItems={{ base: 'start', xl: 'center' }}
                            ></DropDownMenu>
                            <Stack ml="auto !important">{!isPC && <Stack ml="auto !important">{accountBlock}</Stack>}</Stack>
                        </HStack>

                        <Stack
                            direction={{ base: 'row', xl: 'row' }}
                            flex={{ base: 'unset', xl: '1' }}
                            justifyContent="end"
                            ml={{ base: 'auto', xl: '' }}
                            alignItems={{ base: 'center', xl: 'center' }}
                            mr="20px"
                        >
                            {toolBlock}
                            {account ? (
                                isPC && accountBlock
                            ) : (
                                <Flex ml="auto" alignItems="center" pr="0px">
                                    <NetworkSelect isHome={true} showArrow={false} />
                                    <ConnectButton variant="purple" mr="10px" Identicon={Identicon} onClick={onConnect} />
                                </Flex>
                            )}
                        </Stack>
                    </Flex>
                )}
            </Flex>
            <WalletModal
                account={account as unknown as string}
                chainId={chainId!}
                isOpen={isOpen}
                onClose={onClose}
                onCopyAddress={onCopyAddress}
                scanName={chainName}
            />
        </>
    );
};

export default Header;
